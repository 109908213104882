export const BASE_API = import.meta.env.VITE_BASE_API
export const DEV = 'dev'
export const TEST = 'test'
export const STAGE = 'stage'

export const ZEUS_TOKEN = 'zeus-token'
export const ZEUS_USER_INFO = 'zeus-user-info'

export const ADMIN = 'admin'
export const DELETE_OK = 'DELETE_OK'
export const DELETE_FAILED = 'DELETE_FAILED'
export const FETCH_OK = 'FETCH_OK'
export const UNEXPECTED_STATE = 'UNEXPECTED_STATE'
export const TC_POST_OK = 'TC_POST_OK'
export const TEAM_POST_OK = 'TEAM_POST_OK'
export const POST_OK = 'POST_OK'
export const FETCH_ERROR = 'FETCH_ERROR'
export const POST_ERROR = 'POST_ERROR'
export const NOT_POSTED = 'NOT_POSTED'
export const FAILED_REVERT = 'FAILED_REVERT'
export const FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED'
export const REVERT_OK = 'REVERT_OK'
export const OK = 'OK'
export const ERROR = 'ERROR'
export const PUT = 'PUT'
export const BLUR_EMIT = 'blur'
export const SET_CURRENT_TEAM_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTeamIdAndOpenHistory'
export const SET_CURRENT_TEAM_CONTAINER_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTeamContainerIdAndOpenHistory'
export const TOGGLE_HISTORY_EMIT = 'toggleHistory'
export const SET_CURRENT_TOPIC_CONTAINER_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTopicContainerIdAndOpenHistory'
export const SET_CURRENT_TOPIC_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTopicIdAndOpenHistory'
export const POST = 'POST'
export const GET = 'GET'
export const BEARER = 'Bearer'
export const DELETE = 'DELETE'

export enum TeamContainerCol {
  ID = 'ID',
  SHORT_NAME = 'SHORT_NAME',
  LONG_NAME = 'LONG_NAME',
  TEAMS = 'TEAMS',
}

export enum TeamCol {
  ID = 'ID',
  SHORT_NAME = 'SHORT_NAME',
  LONG_NAME = 'LONG_NAME',
  MAJOR_RANK = 'MAJOR_RANK',
  MINOR_RANK = 'MINOR_RANK',
  MEDIA_OBJECT_PRODUCER = 'MEDIA_OBJECT_PRODUCER',
  VALID = 'VALID',
  DEFAULT_CATEGORY_ID = 'DEFAULT_CATEGORY_ID',
  DEFAULT_GENRE_ID = 'DEFAULT_GENRE_ID',
  EDITORIAL = 'EDITORIAL',
  PRODUCT_IDS = 'PRODUCT_IDS',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  EMPTY = 'EMPTY',
}

export enum Sort {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum SortBy {
  NONE = 'NONE',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
  LONG_NAME_ASC = 'LONG_NAME_ASC',
  LONG_NAME_DESC = 'LONG_NAME_DESC',
  MAJOR_RANK_ASC = 'MAJOR_RANK_ASC',
  MAJOR_RANK_DESC = 'MAJOR_RANK_DESC',
  MINOR_RANK_ASC = 'MINOR_RANK_ASC',
  MINOR_RANK_DESC = 'MINOR_RANK_DESC',
  DEFAULT_CATEGORY_ID_ASC = 'DEFAULT_CATEGORY_ID_ASC',
  DEFAULT_CATEGORY_ID_DESC = 'DEFAULT_CATEGORY_ID_DESC',
  DEFAULT_GENRE_ID_ASC = 'DEFAULT_GENRE_ID_ASC',
  DEFAULT_GENRE_ID_DESC = 'DEFAULT_GENRE_ID_DESC',
  PRODUCT_IDS_ASC = 'PRODUCT_IDS_ASC',
  PRODUCT_IDS_DESC = 'PRODUCT_IDS_DESC',
  PHONE_ASC = 'PHONE_ASC',
  PHONE_DESC = 'PHONE_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  VALID_ASC = 'VALID_ASC',
  VALID_DESC = 'VALID_DESC',
  MEDIA_OBJECT_PRODUCER_ASC = 'MEDIA_OBJECT_PRODUCER_ASC',
  MEDIA_OBJECT_PRODUCER_DESC = 'MEDIA_OBJECT_PRODUCER_DESC',
  EDITORIAL_ASC = 'EDITORIAL_ASC',
  EDITORIAL_DESC = 'EDITORIAL_DESC',
  TEAMS_ASC = 'TEAMS_ASC',
  TEAMS_DESC = 'TEAMS_DESC',
}

export enum TeamErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
}

export enum RubricInfoCode {
  ID_MUST_BE_SET = 'ID_MUST_BE_SET',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
}

export enum RubricErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
}

export enum TopicContainerNewRowEmits {
  REFRESH_TABLE = 'refreshTable',
  EMIT_TABLE = 'emitTable',
  SORT_TABLE = 'sortTable',
}

export enum TopicContainerTableCellEditableEmits {
  IS_EMPTY = 'isEmpty',
  BLUR = 'blur',
  FOCUS = 'focus',
}

export enum TopicContainerErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
  PUT_ERROR = 'PUT_ERROR',
}

export enum TopicInfoCode {
  POST_OK = 'POST_OK',
  FETCH_OK = 'FETCH_OK',
  INCONSISTENT_LONGNAME_SHORTNAME = 'INCONSISTENT_LONGNAME_SHORTNAME',
}

export enum TopicErrorCode {
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  INCONSISTENT_LONGNAME_SHORTNAME = 'INCONSISTENT_LONGNAME_SHORTNAME',
}

export enum TopicContainerInfoCode {
  DELETE_TOPIC_CONTAINERS_HAD_ERRORS_REVERTING = 'DELETE_TOPIC_CONTAINERS_HAD_ERRORS_REVERTING',
  LANGUAGE_MISMATCH = 'LANGUAGE_MISMATCH',
  MINIMUM_ONE_RESSORT = 'MINIMUM_ONE_RESSORT',
  TOPIC_UNSAVED_CHANGES = 'TOPIC_UNSAVED_CHANGES',
  ID_MUST_BE_SET = 'ID_MUST_BE_SET',
  DELETE_OK = 'DELETE_OK',
  SAVE_OK = 'SAVE_OK',
  INVALID_ID = 'INVALID_ID',
  FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED',
  PUT_OK = 'PUT_OK',
}

export enum TableEmits {
  SORT = 'sort',
}

export enum DropDownEmits {
  UPDATE_SEARCH_TEXT = 'updateSearchText',
  UPDATE_ITEM_CANDIDATE = 'updateItemCandidate',
  DELETE_ITEM = 'deleteItem',
  ADD_ITEM_CANDIDATE = 'addItemCandidate',
  NO_ITEMS = 'noItems',
}
export enum DropDownEvents {
  CandidateFirstItem = 'CANDIDATE_FIRST_ITEM',
  CandidateNextItem = 'CANDIDATE_NEXT_ITEM',
  CandidateLastItem = 'CANDIDATE_LAST_ITEM',
  CandidatePreviousItem = 'CANDIDATE_PREVIOUS_ITEM',
  CandidateNoItem = 'CANDIDATE_NO_ITEM',
  CandidateSpecificItem = 'CANDIDATE_SPECIFIC_ITEM',
}

export enum StafferRightsFlyByInfoCode {
  SETTINGS_WERE_RESET = 'SETTINGS_WERE_RESET',
  SETTINGS_WERE_SAVED = 'SETTINGS_WERE_SAVED',
  ADMIN_RIGHTS_REACTIVATED = 'ADMIN_RIGHTS_REACTIVATED',
}

export enum StafferRightsInfoCode {
  LOSE_OWN_ADMIN_RIGHTS_MSG = 'LOSE_OWN_ADMIN_RIGHTS_MSG',
  UNSAVED_CHANGES_MSG = 'UNSAVED_CHANGES_MSG',
}

export enum StafferRightsErrorCode {
  STAFFER_LIST_COULD_NOT_BE_FETCHED = 'STAFFER_LIST_COULD_NOT_BE_FETCHED',
  NO_USER_ID_ERROR = 'NO_USER_ID_ERROR',
  HISTORY_FETCH_FAILED = 'HISTORY_FETCH_FAILED',
  MISSING_SEARCH_STRING = 'MISSING_SEARCH_STRING',
  STAFFER_DATA_COULD_NOT_BE_FETCHED = 'STAFFER_DATA_COULD_NOT_BE_FETCHED',
  ROLE_LIST_FETCH_FAILED = 'ROLE_LIST_FETCH_FAILED',
  SAVE_FAILED = 'SAVE_FAILED',
}
export enum Roles {
  Produce = 'produce',
  Stafferdispo = 'stafferdispo',
  Assign = 'assign',
  Release = 'release',
  Publish = 'publish',
  Admin = 'admin',
  Videoadmin = 'videoadmin',
  Organisation = 'organisation',
}

export enum Languages {
  German = 'de',
  English = 'en',
  Spanish = 'es',
}
